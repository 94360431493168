module.exports = () => ({
  agen: {
    displayName: 'SU Agen',
    abbreviation: 'AGE',
    nickname: 'Agen',
  },
  aironi: {
    displayName: 'Aironi Rugby',
    abbreviation: 'AIR',
    nickname: 'Aironi',
  },
  argentina: {
    displayName: 'Argentina',
    abbreviation: 'ARG',
    nickname: 'Argentina',
  },
  beziers: {
    displayName: 'AS Béziers Hérault',
    abbreviation: 'BEZ',
    nickname: 'Béziers',
  },
  biarritz: {
    displayName: 'Biarritz Olympique',
    abbreviation: 'BIA',
    nickname: 'Biarritz',
  },
  'border-reivers': {
    displayName: 'Border Reivers',
    abbreviation: 'BOR',
    nickname: 'Reivers',
  },
  'bourgoin-jallieu': {
    displayName: 'Bourgoin Jallieu',
    abbreviation: 'BOU',
    nickname: 'Bourgoin Jallieu',
  },
  bath: {
    displayName: 'Bath Rugby',
    abbreviation: 'BAT',
    nickname: 'Bath',
  },
  'benetton-treviso': {
    displayName: 'Benetton Treviso',
    abbreviation: 'BEN',
    nickname: 'Treviso',
  },
  'bordeaux-begles': {
    displayName: 'Bordeaux Begles',
    abbreviation: 'BOR',
    nickname: 'Bordeaux',
  },
  bristol: {
    displayName: 'Bristol Rugby',
    abbreviation: 'BRI',
    nickname: 'Bristol',
  },
  'british-army': {
    displayName: 'British Army',
    abbreviation: 'ARM',
    nickname: 'Army',
  },
  'bridgend-ravens': {
    displayName: 'Bridgend Ravens',
    abbreviation: 'RAV',
    nickname: 'Ravens',
  },
  caerphilly: {
    displayName: 'Caerphilly',
    abbreviation: 'CAE',
    nickname: 'Caerphilly',
  },
  calvisano: {
    displayName: 'Calvisano',
    abbreviation: 'CAL',
    nickname: 'Calvisano',
  },
  cardiff: {
    displayName: 'Cardiff',
    abbreviation: 'CAR',
    nickname: 'Cardiff',
  },
  'cardiff-blues': {
    displayName: 'Cardiff Blues',
    abbreviation: 'BLU',
    nickname: 'Blues',
  },
  'celtic-warriors': {
    displayName: 'Celtic Warriors',
    abbreviation: 'CEL',
    nickname: 'Warriors',
  },
  colomiers: {
    displayName: 'Colomiers',
    abbreviation: 'COL',
    nickname: 'Colomiers',
  },
  castres: {
    displayName: 'Castres Olympique',
    abbreviation: 'CAS',
    nickname: 'Castres',
  },
  'clermont-auvergne': {
    displayName: 'Clermont Auvergne',
    abbreviation: 'CLE',
    nickname: 'Clermont',
  },
  connacht: {
    displayName: 'Connacht Rugby',
    abbreviation: 'CON',
    nickname: 'Connacht',
  },
  'dinamo-bucuresti': {
    displayName: 'Dinamo Bucuresti',
    abbreviation: 'BUC',
    nickname: 'Dinamo',
  },
  exeter: {
    displayName: 'Exeter Chiefs',
    abbreviation: 'EXE',
    nickname: 'Chiefs',
  },
  'ebbw-vale': {
    displayName: 'Ebbw Vale Steelmen',
    abbreviation: 'EBB',
    nickname: 'Steelmen',
  },
  'el-salvador': {
    displayName: 'El Salvador',
    abbreviation: 'ELS',
    nickname: 'El Salvador',
  },
  'farul-constanta': {
    displayName: 'Farul Constanta',
    abbreviation: 'FAR',
    nickname: 'Farul Constanta',
  },
  'glasgow-warriors': {
    displayName: 'Glasgow Warriors',
    abbreviation: 'GLA',
    nickname: 'Glasgow',
  },
  gloucester: {
    displayName: 'Gloucester Rugby',
    abbreviation: 'GLO',
    nickname: 'Gloucester',
  },
  harlequins: {
    displayName: 'Harlequins',
    abbreviation: 'HAR',
    nickname: 'Quins',
  },
  'la-rochelle': {
    displayName: 'La Rochelle',
    abbreviation: 'LAR',
    nickname: 'La Rochelle',
  },
  leicester: {
    displayName: 'Leicester Tigers',
    abbreviation: 'LEI',
    nickname: 'Tigers',
  },
  leinster: {
    displayName: 'Leinster Rugby',
    abbreviation: 'LNR',
    nickname: 'Leinster',
  },
  'london-irish': {
    displayName: 'London Irish',
    abbreviation: 'LIR',
    nickname: 'Irish',
  },
  'london-welsh': {
    displayName: 'London Welsh',
    abbreviation: 'LWE',
    nickname: 'Welsh',
  },
  montpellier: {
    displayName: 'Montpellier Rugby',
    abbreviation: 'MON',
    nickname: 'Montpellier',
  },
  munster: {
    displayName: 'Munster Rugby',
    abbreviation: 'MUN',
    nickname: 'Munster',
  },
  narbonne: {
    displayName: 'Racing Club Narbonne Mediterranee',
    abbreviation: 'NAR',
    nickname: 'Narbonne',
  },
  neath: {
    displayName: 'Neath',
    abbreviation: 'NEA',
    nickname: 'Neath',
  },
  newcastle: {
    displayName: 'Newcastle Falcons',
    abbreviation: 'NEW',
    nickname: 'Falcons',
  },
  'newport-gwent-dragons': {
    displayName: 'Dragons',
    abbreviation: 'DRA',
    nickname: 'Dragons',
  },
  northampton: {
    displayName: 'Northampton Saints',
    abbreviation: 'NOR',
    nickname: 'Saints',
  },
  nottingham: {
    displayName: 'Nottingham Rugby',
    abbreviation: 'NOT',
    nickname: 'Nottingham',
  },
  ospreys: {
    displayName: 'Ospreys Rugby',
    abbreviation: 'OSP',
    nickname: 'Ospreys',
  },
  oyonnax: {
    displayName: 'Oyonnax Rugby',
    abbreviation: 'OYO',
    nickname: 'Oyonnax',
  },
  perpignan: {
    displayName: 'Perpignan',
    abbreviation: 'PER',
    nickname: 'Perpignan',
  },
  petrarca: {
    displayName: 'Petrarca',
    abbreviation: 'PET',
    nickname: 'Petrarca',
  },
  'racing-92': {
    displayName: 'Racing 92',
    abbreviation: 'RAC',
    nickname: 'Racing',
  },
  richmond: {
    displayName: 'Richmond',
    abbreviation: 'RIC',
    nickname: 'Richmond',
  },
  rotherham: {
    displayName: 'Rotherham Titans',
    abbreviation: 'ROT',
    nickname: 'Titans',
  },
  sale: {
    displayName: 'Sale Sharks',
    abbreviation: 'SAL',
    nickname: 'Sharks',
  },
  saracens: {
    displayName: 'Saracens',
    abbreviation: 'SAR',
    nickname: 'Saracens',
  },
  scarlets: {
    displayName: 'Scarlets',
    abbreviation: 'SCA',
    nickname: 'Scarlets',
  },
  'stade-français': {
    displayName: 'Stade Français',
    abbreviation: 'STA',
    nickname: 'Stade',
  },
  'timisoara-saracens': {
    displayName: 'Timisoara Saracens',
    abbreviation: 'TSA',
    nickname: 'Saracens',
  },
  toulon: {
    displayName: 'Rugby Club Toulonnais',
    abbreviation: 'TLN',
    nickname: 'Toulon',
  },
  toulouse: {
    displayName: 'Stade Toulousain',
    abbreviation: 'TLS',
    nickname: 'Toulouse',
  },
  ulster: {
    displayName: 'Ulster Rugby',
    abbreviation: 'ULS',
    nickname: 'Ulster',
  },
  'us-dax': {
    displayName: 'US Dax',
    abbreviation: 'USD',
    nickname: 'US Dax',
  },
  viadana: {
    displayName: 'Viadana',
    abbreviation: 'VIA',
    nickname: 'Viadana',
  },
  wasps: {
    displayName: 'Wasps',
    abbreviation: 'WAS',
    nickname: 'Wasps',
  },
  'west-hartlepool': {
    displayName: 'West Hartlepool',
    abbreviation: 'WHA',
    nickname: 'West Hartlepool',
  },
  worcester: {
    displayName: 'Worcester Warriors',
    abbreviation: 'WOR',
    nickname: 'Warriors',
  },
  'yorkshire-carnegie': {
    displayName: 'Yorkshire Carnegie',
    abbreviation: 'YOR',
    nickname: 'Carnegie',
  },
  zebre: {
    displayName: 'Zebre Rugby',
    abbreviation: 'ZEB',
    nickname: 'Zebre',
  },
  'vodacom-bulls':{
    displayName: 'Vodacom Bulls',
    abbreviation: 'VOD',
    nickname: 'Vodacom Bulls'
  }
})
